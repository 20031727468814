import cx from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useMemo } from 'react';

type Props = {
    className?: string;
    classHeader?: string;
};

const Header: FC<Props> = ({
    className = 'absolute top-0 left-0 right-0 pt-5 text-white',
    classHeader = '',
}) => {
    const { pathname, push } = useRouter();

    const isMenuCompleteOrder: boolean = useMemo(() => {
        return ['/complete-order'].some((i: string) => pathname.includes(i));
    }, [pathname]);

    const isHome: boolean = useMemo(() => {
        return pathname === '/';
    }, [pathname]);

    const onClickLogo = useCallback(() => {
        push('/');
    }, [push]);

    const router = useRouter();
    const showBackArrow = ['complete-order'].some((el) =>
        pathname.includes(el),
    );

    const onClickBackArrow = (): void => {
        if (router.pathname.includes('[id]')) {
            router.push(`/create-order`);
        } else {
            router.back();
        }
    };
    return (
        <>
            <header
                className={cx([className, 'z-20 h-15 md:h-20', classHeader])}
                id="navbar"
            >
                <nav
                    className={cx(
                        [
                            'mx-0 md:mx-auto w-full flex items-center px-5 md:px-0 xl:container',
                        ],
                        { 'md:justify-start justify-between': isHome },
                        { 'justify-center': !isHome },
                    )}
                >
                    {isMenuCompleteOrder && (
                        <div
                            className="md:flex create-order-left items-center flex-shrink-0 mr-6 px-5 md:px-0 flex-grow"
                            onClick={onClickBackArrow}
                            aria-hidden="true"
                        >
                            {showBackArrow && (
                                <div className="cursor-pointer">
                                    <Image
                                        src="/images/arrow-left.png"
                                        height={14}
                                        width={18}
                                        alt=""
                                    />
                                </div>
                            )}
                        </div>
                    )}
                    <div
                        className={cx(
                            ['flex items-center md:flex-shrink-0 md:px-6'],
                            { 'md:flex-grow': isMenuCompleteOrder },
                        )}
                    >
                        <button
                            type="button"
                            onClick={onClickLogo}
                            className={cx(
                                [
                                    'flex btn-nav-brand font-bold items-center focus:no-underline focus:outline-none pl-0 text-lg',
                                ],
                                {
                                    'md:pl-10': isMenuCompleteOrder,
                                    'text-white md:text-black': isHome,
                                    'text-black': !isHome,
                                },
                            )}
                        >
                            <Image
                                src="/images/logo.png"
                                alt=""
                                height={27}
                                width={25}
                            />
                            <p className="text-18px font-Circular-Std text-main-back font-black ml-10px">
                                Tikfamecontentboost
                            </p>
                        </button>
                    </div>
                </nav>
            </header>
            <style jsx>
                {`
                    .btn-nav-brand,
                    .btn-my-order {
                        color: white;
                    }
                    .dashboard .btn-nav-brand,
                    .dashboard .btn-my-order,
                    .header-shadow .btn-nav-brand,
                    .header-shadow .btn-my-order {
                        color: #090b17;
                    }
                    @media (max-width: 767px) {
                        .dashboard {
                            height: 84px;
                            background: #ffffff;
                            box-shadow: 0px 3px 4px rgba(211, 218, 225, 0.25);
                            display: flex;
                            padding-top: 0;
                            align-items: center;
                        }
                        .create-order-left {
                            position: absolute;
                            left: 0;
                        }
                    }
                    .header-shadow {
                        height: 84px;
                        background: white;
                        border: 1px solid #e4e6eb;
                        display: flex;
                        padding-top: 0;
                        align-items: center;
                    }
                    .mobile-hidden {
                        display: none;
                    }
                    @media (min-width: 768px) {
                        .mobile-hidden {
                            display: flex;
                        }
                    }
                `}
            </style>
        </>
    );
};

export default Header;
